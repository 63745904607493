import React, {useState} from 'react';
import {
  Grid,
  Typography,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  FormLabel,
  FormHelperText,
  Chip,
  Dialog,
  DialogTitle,
} from '@material-ui/core';
import {useSnackbar} from 'notistack';
import ReturnButton from '../../../components/ReturnButton';
import EditorComponent from './ComponentEditorJs';
import {Formik} from 'formik';
import InputText from '../../../components/forms/InputText';
import ButtonSubmit from '../../../components/forms/ButtonSubmit';
import AnimatedView from '../../../components/AnimatedView';
import moment from 'moment';
import {BodyEmail} from '../assets/BodyEmail';
import InputImage from '../../../components/forms/InputImage';
import {toDataURL} from '../../../core/toDataURL';
import {DeleteOutline} from '@material-ui/icons';
import api from '../../../core/api';
import Confirm from '../../../components/Confirm';
import ButtonForm from '../../../components/forms/ButtonForm';
import {ModalFormNewsletter} from './ModalFormNewsletter';
import {ModalFormNewsletterTest} from './ModalFormNewsletterTest';

const NewsletterForm = ({
  news = {
    id: '',
    body: '',
    button: '',
    link: '',
    title: '',
    subject: '',
    image: '',
    usersType: '',
    numberOfShots: 0,
  },
  history,
}) => {
  const editing = !!news.id;
  const [usersType, setUsersType] = useState(news?.usersType || []);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [body, setBody] = useState(
    news?.body || `<p>Adicione o corpo do e-mail</p>`,
  );
  const [form, setForm] = useState({
    subject: news?.subject || '',
    title: news?.title || '',
    buttonName: news?.button || null,
    buttonLink: news?.link || null,
  });

  const [loadingImage, setLoadingImage] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(news?.image || null);

  const [open, setOpen] = useState(false);
  const [openSendEmail, setOpenSendEmail] = useState(false);
  const [dataEdit, setDataEdit] = useState(false);

  const [update, setUpdate] = useState(false);

  const SetUpdate = () => {
    usersType.includes('ALL') && setUsersType(['F', 'N', 'M', 'Y', 'S', 'G']);
    setUpdate(!update);
  };

  const {enqueueSnackbar} = useSnackbar();

  const SaveImage = async () => {
    setLoadingImage(true);
    try {
      const formData = new FormData();
      formData.append('file', selectedFile);
      const response = await api.post('newsletter/images', formData, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      });
      setPreview(response.data);
      return response.data;
    } catch (error) {
      enqueueSnackbar('Erro ao carregar imagem.');
      return 'error';
    } finally {
      setLoadingImage(true);
    }
  };

  const removeUsers = async premium => {
    try {
      setUsersType(prevState => {
        return [...prevState.filter(item => item != premium)];
      });
    } catch ({response}) {
      enqueueSnackbar(
        response.data || 'Erro ao remover esse tipo de plano da newsletter.',
      );
    }
  };

  const onSubmit = async form => {
    // const [startDay, startMonth, startYear] = form.shippingDate.split('/');
    // const shippingDate = new Date(`${startYear}/${startMonth}/${startDay}`);

    // if (isNaN(shippingDate)) {
    //   enqueueSnackbar('Data de envio inválida', {
    //     preventDuplicate: true,
    //     variant: 'error',
    //     style: {whiteSpace: 'pre-line'},
    //   });
    //   return;
    // }

    // if (new Date() > shippingDate) {
    //   enqueueSnackbar('Data de envio menor que a data atual', {
    //     preventDuplicate: true,
    //     variant: 'error',
    //     style: {whiteSpace: 'pre-line'},
    //   });
    //   return;
    // }

    let url = preview || '';
    if (selectedFile) {
      url = await SaveImage();
      if (url === 'error') return;
    }

    const payload = {
      subject: form.subject || '',
      numberOfShots: form.numberOfShots || 0,
      body: JSON.stringify({
        title: form.title,
        image: url,
        body: form.body || '',
        button: form.button || '',
        link: form.link || '',
      }),
      usersType: JSON.stringify(usersType),
      status: 'Active',
      // shippingDate: `${startYear}/${startMonth}/${startDay}`,
    };
    setLoading(true);
    try {
      if (editing) {
        await api.put(`/newsletter/${news.id}`, payload).then(res => {
          // console.log(res);
          if (res.status === 200) {
            enqueueSnackbar('Newsletter alterada com sucesso!');
            history.push('/newsletter');
          }
        });
      } else {
        await api.post('/newsletter', payload).then(res => {
          if (res.status === 200) {
            enqueueSnackbar('Nova Newsletter cadastrada com sucesso!');
            history.push('/newsletter');
          }
        });
      }
    } catch ({response}) {
      enqueueSnackbar(
        response.data.message ||
          'Ocorreu um erro ao cadastrar newsletter. Tente novamente.',
      );
      setLoading(false);
    }
  };

  const validateForm = values => {
    const errors = {};
    let errorMessage = '';
    if (!values.subject) {
      errors.subject = 'Digite o assunto do email';
      errorMessage += 'Assunto do E-mail\n';
    }

    if (!values.title) {
      errors.title = 'Digite o Título';
      errorMessage += 'Título da Newsletter\n';
    }

    // if (!values.shippingDate) {
    //   errors.shippingDate = 'Digite a data de envio';
    //   errorMessage += 'Data de Envio\n';
    // }

    if (!values.body) {
      errors.body = 'Digite o corpo do E-mail';
      errorMessage += 'Corpo do E-mail\n';
    }

    if (Object.keys(errors).length) {
      enqueueSnackbar(
        'Formulário incompleto, campos a serem preenchidos:\n' + errorMessage,
        {
          preventDuplicate: true,
          variant: 'error',
          style: {whiteSpace: 'pre-line'},
        },
      );
    }

    return errors;
  };

  const ConfirmSubmit = async values => {
    const res = await Confirm(
      'Newsletter não atribuida a nenhum tipo de usuário. Deseja realmente salvar?',
    );
    if (res) {
      try {
        onSubmit(values);
      } catch (e) {
        console.log({e});
      }
    }
  };

  const handleFileChange = event => {
    const file = event.target.files[0];
    setLoadingImage(true);
    try {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        toDataURL(
          reader.result,
          function(dataUrl) {
            // console.log('RESULT:', dataUrl);
            setPreview(dataUrl);
          },
          'image/jpeg',
        ),
      );
      reader.readAsDataURL(file);
    } catch (e) {
      enqueueSnackbar('Erro ao carregar imagem.');
    } finally {
      setLoadingImage(false);
    }
  };

  const SetBodyEmail = () => {
    return BodyEmail(
      form.subject,
      form.title,
      body,
      form.buttonName,
      form.buttonLink,
      preview,
    );
  };

  const DuplicateNewsletter = async () => {
    setLoading(true);
    try {
      await api.post(`/newsletter/duplicate/${news.id}`);
      history.push('/newsletter');
      enqueueSnackbar('Newsletter duplicada com Sucesso!');
    } catch (response) {
      enqueueSnackbar(
        response.data.message ||
          'Ocorreu um erro ao duplicar newsletter. Tente novamente.',
      );
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  // const SendEmailNewsletter = async () => {
  //   setLoading(true);
  //   try {
  //     await api.post(`/newsletter/send-email/${news.id}`, {
  //       name: 'Luciano',
  //       email: 'berta.santos.moreira@gmail.com',
  //     });
  //     // history.push('/newsletter');
  //     // enqueueSnackbar('Newsletter duplicada com Sucesso!');
  //   } catch (response) {
  //     enqueueSnackbar(
  //       response.data.message ||
  //         'Ocorreu um erro ao duplicar newsletter. Tente novamente.',
  //     );
  //     setLoading(false);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  return (
    <>
      <Formik
        initialValues={news}
        validate={validateForm}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={values => {
          usersType.length > 0 ? onSubmit(values) : ConfirmSubmit(values);
        }}>
        {formikProps => (
          <Grid container xs={12}>
            <Grid item xs={12} alignContent="center">
              <Typography component="h1" variant="h5">
                <ReturnButton />
                {editing ? 'Editar Newsletter' : 'Cadastrar Nova Newsletter'}
              </Typography>
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              {!loading ? (
                <form onSubmit={formikProps.handleSubmit}>
                  {editing && (
                    <div style={{w: '100%', display: 'flex'}}>
                      <FormControl
                        fullWidth
                        error={
                          formikProps.touched.status &&
                          formikProps.errors.status
                        }>
                        <AnimatedView>
                          <FormLabel>Status</FormLabel>
                        </AnimatedView>
                        <Select
                          style={{
                            borderRadius: '40px',
                            height: 50,
                            border: 'solid 0.5px #d3d3d3',
                            padding: '0 20px',
                            marginTop: 10,
                          }}
                          variant="outlined"
                          disabled
                          value={formikProps.values.status}
                          label="Status Assinatura"
                          onChange={e => {
                            setDataEdit(true);
                            formikProps.setFieldValue('status', e.target.value);
                          }}>
                          {[
                            {key: 'Active', label: 'Ativo'},
                            {key: 'Inactive', label: 'Inativo'},
                          ].map(e => {
                            return <MenuItem value={e.key}>{e.label}</MenuItem>;
                          })}
                        </Select>
                        {formikProps.touched.status &&
                          formikProps.errors.status && (
                            <FormHelperText id="component-error-text">
                              {formikProps.touched.status &&
                                formikProps.errors.status}
                            </FormHelperText>
                          )}
                      </FormControl>
                      <div style={{marginLeft: 10}} />

                      <FormControl
                        fullWidth
                        error={
                          formikProps.touched.usersType &&
                          formikProps.errors.usersType
                        }>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginTop: 30,
                          }}>
                          <ButtonForm
                            style={{
                              marginTop: 10,
                              width: '25%',
                              alignSelf: 'center',
                            }}
                            disabled={dataEdit}
                            onClick={DuplicateNewsletter}
                            variant="contained"
                            color="primary">
                            Duplicar Newsletter
                          </ButtonForm>
                          <div style={{marginLeft: 10}} />
                          <ButtonForm
                            style={{
                              marginTop: 10,
                              width: '25%',
                              alignSelf: 'center',
                            }}
                            disabled={dataEdit}
                            onClick={() => {
                              setOpen(true);
                            }}
                            variant="contained"
                            color="primary">
                            Testar Newsletter
                          </ButtonForm>
                          <div style={{marginLeft: 10}} />
                          <ButtonForm
                            style={{
                              marginTop: 10,
                              width: '25%',
                              alignSelf: 'center',
                            }}
                            disabled={dataEdit}
                            onClick={() => {
                              setOpenSendEmail(true);
                            }}
                            variant="contained"
                            color="primary">
                            Enviar Newsletter
                          </ButtonForm>
                        </div>
                      </FormControl>
                    </div>
                  )}
                  <AnimatedView>
                    <FormLabel>Assunto do Email</FormLabel>
                  </AnimatedView>
                  <InputText
                    errorMessage={
                      formikProps.touched.subject && formikProps.errors.subject
                    }
                    id="subject"
                    label=""
                    name="subject"
                    value={formikProps.values.subject}
                    autoComplete="subject"
                    onChange={e => {
                      setDataEdit(true);
                      formikProps.setFieldValue('subject', e.target.value);
                      formikProps.errors['subject'] = null;
                    }}
                  />

                  <AnimatedView>
                    <FormLabel>Título</FormLabel>
                  </AnimatedView>
                  <InputText
                    errorMessage={
                      formikProps.touched.title && formikProps.errors.title
                    }
                    id="title"
                    label=""
                    name="title"
                    value={formikProps.values.title}
                    autoComplete="title"
                    onChange={e => {
                      setDataEdit(true);
                      formikProps.setFieldValue('title', e.target.value);
                      setForm({...form, title: e.target.value});
                      formikProps.errors['title'] = null;
                    }}
                  />

                  <AnimatedView>
                    <FormLabel>Imagem</FormLabel>
                  </AnimatedView>
                  <InputImage
                    errorMessage={
                      formikProps.touched.fileUrl && formikProps.errors.fileUrl
                    }
                    type="file"
                    accept="image/jpeg, image/png"
                    id="fileUrl"
                    label=""
                    name="fileUrl"
                    value={formikProps.values.fileUrl}
                    autoComplete="fileUrl"
                    onChange={e => {
                      setDataEdit(true);
                      handleFileChange(e);
                    }}
                  />
                  {preview && (
                    <div style={{margin: 10}}>
                      <img class="adapt-img" src={preview} alt="" width={100} />
                      <DeleteOutline
                        style={{cursor: 'pointer'}}
                        onClick={() => {
                          setDataEdit(true);
                          setSelectedFile(null);
                          setPreview(null);
                          document.getElementById('fileUrl').value = '';
                        }}
                      />
                    </div>
                  )}

                  <div style={{w: '100%', display: 'flex'}}>
                    <FormControl fullWidth>
                      <AnimatedView>
                        <FormLabel>Nome do botão</FormLabel>
                      </AnimatedView>
                      <InputText
                        id="button"
                        label=""
                        name="button"
                        value={formikProps.values.button}
                        autoComplete="v"
                        onChange={e => {
                          setDataEdit(true);
                          formikProps.setFieldValue('button', e.target.value);
                          setForm({...form, buttonName: e.target.value});
                          formikProps.errors['button'] = null;
                        }}
                      />
                    </FormControl>
                    <div style={{marginLeft: 10}} />

                    <FormControl fullWidth>
                      <AnimatedView>
                        <FormLabel>Link do botão</FormLabel>
                      </AnimatedView>
                      <InputText
                        id="link"
                        label=""
                        name="link"
                        value={formikProps.values.link}
                        autoComplete="v"
                        onChange={e => {
                          setDataEdit(true);
                          setForm({...form, buttonLink: e.target.value});
                          formikProps.setFieldValue('link', e.target.value);
                          formikProps.errors['link'] = null;
                        }}
                      />
                    </FormControl>
                  </div>

                  <div style={{w: '100%', display: 'flex'}}>
                    <FormControl
                      fullWidth
                      error={
                        formikProps.touched.usersType &&
                        formikProps.errors.usersType
                      }>
                      <AnimatedView>
                        <FormLabel>Tipo de Plano</FormLabel>
                      </AnimatedView>

                      <Select
                        style={{
                          borderRadius: '40px',
                          height: 50,
                          border: 'solid 0.5px #d3d3d3',
                          padding: '0 20px',
                          marginTop: 10,
                        }}
                        variant="outlined"
                        value="Tipo de Plano"
                        label="Tipo de Plano"
                        onChange={e => {
                          !usersType.includes(e.target.value) &&
                            usersType.push(e.target.value);
                          SetUpdate();
                          setDataEdit(true);
                        }}>
                        {[
                          {key: 'N', label: 'Novo'},
                          {key: 'F', label: 'Free'},
                          {key: 'M', label: 'Mensal'},
                          {key: 'Y', label: 'Anual'},
                          {key: 'G', label: 'Gift'},
                          {key: 'S', label: 'Especial'},
                          {key: 'ALL', label: 'Todos'},
                        ].map(e => {
                          return <MenuItem value={e.key}>{e.label}</MenuItem>;
                        })}
                      </Select>
                      {formikProps.touched.usersType &&
                        formikProps.errors.usersType && (
                          <FormHelperText id="component-error-text">
                            {formikProps.touched.usersType &&
                              formikProps.errors.usersType}
                          </FormHelperText>
                        )}
                    </FormControl>
                    {/* <InputMask
                        fullWidth
                        errorMessage={
                          formikProps.touched.nome && formikProps.errors.nome
                        }
                        id="shippingDate"
                        label="Data de envio"
                        name="shippingDate"
                        value={formikProps.values.shippingDate}
                        autoComplete="shippingDate"
                        onChange={e => {
                          formikProps.setFieldValue(
                            'shippingDate',
                            e.target.value,
                          );
                          formikProps.errors['shippingDate'] = null;
                        }}
                      /> 
                      <div style={{marginLeft: 10}} /> */}
                  </div>

                  <div
                    style={{
                      maxWidth: '100%',
                      display: 'flex',
                      flexWrap: 'wrap',
                    }}>
                    {usersType &&
                      usersType.length > 0 &&
                      usersType.map(user => (
                        <Chip
                          style={{margin: 5}}
                          variant="outlined"
                          label={GetPremium(user)}
                          onDelete={() => {
                            setDataEdit(true);
                            if (editing) {
                              removeUsers(user);
                            } else {
                              setUsersType(prevState => {
                                return [
                                  ...prevState.filter(item => item != user),
                                ];
                              });
                            }
                            SetUpdate();
                          }}
                        />
                      ))}
                  </div>

                  <Grid
                    item
                    xs={12}
                    style={{
                      padding: '20px',
                      marginTop: '20px',
                    }}>
                    <FormLabel>Corpo do e-mail</FormLabel>
                    <div style={{marginBottom: '10px'}} />
                    <EditorComponent
                      body={body}
                      setBody={setBody}
                      setFormBody={e => {
                        setDataEdit(true);
                        formikProps.setFieldValue('body', e);
                        formikProps.errors['body'] = null;
                      }}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{
                      padding: '20px',
                      marginTop: '20px',
                      height: '500px',
                    }}>
                    <FormLabel>Resultado do e-mail</FormLabel>
                    <div style={{marginBottom: '10px'}} />
                    {loading ? (
                      <div className="flex-col center-a center-b">
                        <CircularProgress size={100} />
                      </div>
                    ) : (
                      <iframe
                        srcDoc={SetBodyEmail()}
                        width="100%"
                        height="100%"
                      />
                    )}
                  </Grid>

                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                    }}>
                    <ButtonSubmit
                      style={{
                        marginTop: 20,
                        width: '20vw',
                        alignSelf: 'center',
                      }}
                      // type="submit"
                      onClick={() => {}}
                      variant="contained"
                      color="primary">
                      {editing ? 'Salvar' : 'Cadastrar'}
                    </ButtonSubmit>
                  </div>
                </form>
              ) : loadingImage ? (
                <div
                  className="flex-col center-a center-b"
                  style={{height: '50vh'}}>
                  <CircularProgress size={100} />
                  <p> Carregando Imagem... </p>
                </div>
              ) : (
                <div
                  className="flex-col center-a center-b"
                  style={{height: '50vh'}}>
                  <CircularProgress size={100} />
                  <p> Carregando... </p>
                </div>
              )}
            </Grid>
          </Grid>
        )}
      </Formik>
      <Dialog
        onClose={() => setOpenModalConfirm(false)}
        aria-labelledby="simple-dialog-title"
        open={openModalConfirm.length > 0}>
        <DialogTitle id="simple-dialog-title">
          já existem newsletter atreladas a esses tipos de assinaturas, deseja
          substituir?
        </DialogTitle>

        {openModalConfirm &&
          openModalConfirm.length > 0 &&
          openModalConfirm.map(items => {
            return (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Chip
                  style={{margin: 5}}
                  variant="outlined"
                  label={`${GetPremium(items.usersType)} - ${items.newsTitle}`}
                  onDelete={() => {
                    setOpenModalConfirm(prevState => {
                      return [
                        ...prevState.filter(
                          item => item.usersType != items.usersType,
                        ),
                      ];
                    });
                    setUsersType(prevState => {
                      return [
                        ...prevState.filter(item => item != items.usersType),
                      ];
                    });
                    SetUpdate();
                  }}
                />
              </div>
            );
          })}

        <ButtonSubmit
          style={{
            margin: 10,
            width: '20vw',
            alignSelf: 'center',
          }}
          type="button"
          variant="contained"
          color="primary"
          onClick={() => {
            setOpenModalConfirm(false);
          }}>
          Confirmar
        </ButtonSubmit>
      </Dialog>

      <ModalFormNewsletterTest
        handleClose={() => setOpen(false)}
        open={open}
        id={news.id}
      />

      <ModalFormNewsletter
        handleClose={() => setOpenSendEmail(false)}
        open={openSendEmail}
        id={news.id}
        usersType={usersType}
      />
    </>
  );
};

export default NewsletterForm;

export function GetPremium(premium) {
  switch (premium) {
    case 'N':
      return 'Plano N';
    case 'F':
      return 'Plano F';
    case 'M':
      return 'Plano M';
    case 'Y':
      return 'Plano Y';
    case 'S':
      return 'Plano S';
    case 'G':
      return 'Plano G';
    case 'ALL':
      return 'Todos';
    default:
      return 'Todos';
  }
}
